export default class Cartorio {
    constructor() {
        (this.bairro = ''),
            (this.cd_ibge_comarca = ''),
            (this.cd_uf = ''),
            (this.cep = ''),
            (this.cnpj = ''),
            (this.complemento = ''),
            (this.email = ''),
            (this.endereco = ''),
            (this.endereco_numero = ''),
            (this.id = null),
            (this.nm_cartorio = ''),
            (this.nm_cidade = ''),
            (this.nm_responsavel_tabelionato = ''),
            (this.nu_cartorio = ''),
            (this.telefone = '');
    }
}
