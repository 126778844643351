<template>
    <div align="center" justify="center" class="pa-3">
        <v-card align="left" justify="left">
            <v-card-text class="pa-3 font-weight-light white black--text">
                <v-icon class="primary--text lighten-2 pa-2">
                    mdi-office-building-marker</v-icon
                >Cartórios > Listar Cartórios
            </v-card-text>
        </v-card>
        <!-- Cabeçalho -->
        <div align="center" justify="center">
            <v-card
                @keyup.native.enter="botaoListarCartorios"
                class="pa-5 my-3"
                elevation="0"
            >
                <v-row class="">
                    <v-col cols="3" xs="2" sm="2" md="3" lg="2" class="">
                        <v-text-field
                            solo
                            elevation="0"
                            prepend-icon="mdi-chevron-right"
                            x-small
                            label="Código IBGE Cartório"
                            hint="Informe o Código IBGE do cartório"
                            placeholder="Ex.:0000349"
                            v-model="filtros.cd_ibge_comarca"
                            clearable
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" xs="2" sm="2" md="3" lg="2" class="">
                        <v-text-field
                            solo
                            small
                            elevation="0"
                            label="Número do Cartório"
                            hint="Informe o número para efetuar a busca"
                            placeholder="Ex.: 1"
                            clearable
                            v-model="filtros.nu_cartorio"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" xs="2" sm="2" md="3" lg="3" class="">
                        <v-text-field
                            solo
                            small
                            label="Nome do Cartório"
                            hint="Informe o nome para efetuar a busca"
                            placeholder="Ex.: Tabelionato"
                            clearable
                            v-model="filtros.nm_cartorio"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="10" xs="2" sm="2" md="2" lg="2" class="">
                        <v-select
                            solo
                            small
                            clearable
                            v-model="filtros.cd_uf"
                            :items="listaUfs"
                            label="UF"
                            :return-object="true"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="3" xs="2" sm="2" md="3" lg="3" class="">
                        <v-text-field
                            solo
                            small
                            label="Nome da Cidade"
                            hint="Informe o nome da cidade"
                            placeholder="Ex.: Curitiba"
                            v-model="filtros.nm_municipio"
                            clearable
                        ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="12" lg="12" class="text-right">
                        <v-btn
                            color="green white--text"
                            @click="botaoListarCartorios"
                            data-cy="buscarCartoriosBtn"
                        >
                            Buscar Cartórios
                            <v-icon class="ml-2">search</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <!-- fim do cabeçalho -->
            <!-- Inicio tabela resultados -->
            <Snackbar
                v-if="snackbar"
                :mostrarSnackbar="snackbar"
                :corSnackbar="snackbarColor"
                :mensagemSnackbar="mensagemRetorno"
                @fecharSnackbar="snackbar = false"
            />
            <v-data-table
                data-cy="dataTableCartorios"
                :loading="loading"
                no-data-text="Não há cartórios listados"
                loading-text="Carregando Cartórios..."
                :headers="headers"
                :items="cartorios"
                item-key="cartorios"
                class="elevation-1 pa-3"
                :footer-props="{
                    itemsPerPageOptions: [10, 20, 50, -1],
                    itemsPerPageText: 'Cartórios por página:'
                }"
            >
                <template v-slot:[`item.st_ativo`]="{ item }">
                    <v-switch
                        :color="item.st_ativo != '' ? 'green' : 'red'"
                        readonly
                        v-model="item.st_ativo"
                        inset
                        :style="{
                            color: item.st_ativo != '' ? 'green' : 'red'
                        }"
                    >
                    </v-switch>
                </template>
                <template v-slot:[`item.is_admin`]="{ item }">
                    <v-icon
                        :style="{
                            color: item.is_admin != '' ? 'green' : 'red'
                        }"
                        >{{ getBolean(item.is_admin) }}</v-icon
                    >
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <span v-if="item.status == 'true'">
                        <p>Ativo</p>
                    </span>
                    <span v-if="item.cd_status == 'false'">
                        <p>Desabilitado</p>
                    </span>
                </template>
                <template slot="item.action" slot-scope="props">
                    <v-btn
                        elevation="0"
                        width="90%"
                        small
                        @click="exibirDetalhe(props.item)"
                        class="mr-2 grey white--text"
                        :data-cy="`editarCartorio${props.index}`"
                    >
                        <v-icon
                            medium
                            small
                            class="mr-2"
                            @click="exibirDetalhe(props.item)"
                            :style="{ color: 'white' }"
                            >mdi-office-building-marker </v-icon
                        >Ver Cartório
                    </v-btn>
                </template>
            </v-data-table>
            <!-- Fim tabela resultados -->
        </div>
        <!-- Tela para visualizar cartório -->

        <v-dialog
            v-if="dialogEditar"
            v-model="dialogEditar"
            max-width="1200px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- <form @submit.prevent="submit">
                <form ref="cartorioForm"> -->
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="closeDialogEditar">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>

                        <v-col cols="12" sm="2" align="left">
                            <v-toolbar-title
                                >Visualizar Cartório</v-toolbar-title
                            >
                        </v-col>
                    </v-toolbar>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-8 py-4">
                    <EditarCartorio
                        :cartorio="cartorio"
                        :validarForm="validarForm"
                        @formValidado="validarForm = $event"
                        @setIsInvalid="isInvalid = $event"
                        @fecharModal="closeDialogEditar"
                    />
                </v-card-text>
                <v-divider />
                <v-card-actions
                    style="display: flex; justify-content: end; padding: 8px 48px 8px 16px"
                >
                    <v-btn
                        class="ml-5"
                        color="success white--text"
                        @click="validarSalvarCartorio"
                    >
                        <v-icon class="">mdi-chevron-right</v-icon>Salvar
                        Alterações
                    </v-btn>
                    <v-btn
                        class="ml-5"
                        color="grey white--text"
                        @click="closeDialogEditar"
                    >
                        <v-icon class="">mdi-chevron-right</v-icon>Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
            <!-- </form>
            </form> -->
            <!-- fim da tela para editar usuário --------------------- -->
        </v-dialog>
    </div>
</template>
<script>
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import CartorioService from '@/services/CartorioService';
import Cartorio from '@/domains/Cartorios';
import EditarCartorio from '@/components/EditarCartorio';
import { required, email, max } from 'vee-validate/dist/rules';
import { extend, setInteractionMode } from 'vee-validate';

setInteractionMode('eager');

extend('required', {
    ...required,
    message: '{_field_} can not be empty'
});

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters'
});

extend('email', {
    ...email,
    message: 'Email must be valid'
});

export default {
    name: 'ListaCartorios',
    mixins: [validationMixin],
    components: {
        EditarCartorio
    },

    validations: {
        checkbox: {
            checked(val) {
                return val;
            }
        }
    },

    data() {
        return {
            isInvalid: false,
            validarForm: false,
            loading: false,
            switch1: true,
            switch0: false,
            filtros: {
                cd_ibge_comarca: null,
                nm_cartorio: null,
                nu_cartorio: null,
                cd_uf: null
            },
            listaUfs: [
                '',
                'AC',
                'AL',
                'AP',
                'AM',
                'BA',
                'CE',
                'DF',
                'ES',
                'GO',
                'MA',
                'MT',
                'MS',
                'MG',
                'PA',
                'PB',
                'PR',
                'PE',
                'PI',
                'RJ',
                'RN',
                'RS',
                'RO',
                'RR',
                'SC',
                'SP',
                'SE',
                'TO'
            ],
            dialog: false,
            dialogEditar: false,
            loadingFiltros: null,
            data_inicio: null,
            data_fim: null,
            menu1: false,
            menu2: false,
            modal: false,
            modal2: false,
            cartao: true,
            boleto: true,
            status: true,
            id: null,
            ultimoFiltro: null,
            pagina: 1,
            showPagination: false,
            snackbar: false,
            mensagemRetorno: null,
            snackbarColor: 'green white--text',
            mensagem: null,
            headers: [
                {
                    text: 'Código IBGE',
                    sortable: false,
                    value: 'cd_ibge_comarca'
                },
                {
                    text: 'Número',
                    sortable: false,
                    value: 'nu_cartorio'
                },
                {
                    text: 'Nome do Cartório',
                    sortable: false,
                    value: 'nm_cartorio'
                },
                {
                    text: 'UF',
                    sortable: false,
                    value: 'cd_uf'
                },
                {
                    text: 'Cidade',
                    sortable: false,
                    value: 'nm_cidade'
                },
                {
                    text: 'Telefone',
                    sortable: false,
                    value: 'telefone'
                },
                {
                    text: 'E-mail',
                    sortable: false,
                    value: 'email'
                },
                { text: ' ', sortable: false, value: 'action' }
            ],
            cartorios: [],
            cartorio: [],
            cartorioDetalhe: [
                {
                    cd_ibge_comarca: '111',
                    nm_cartorio:
                        '1 TABELIONATO DE PROTESTO DE TÍTULOS DE CURITIBA',
                    nu_cartorio: '1',
                    cd_uf: 'PR',
                    telefone_cartorio: '41-99999-9999',
                    endereco_cartorio: 'Rua Teste, 200'
                }
            ],
            listaDeCartorios: [
                {
                    cd_ibge_comarca: '111',
                    nm_cartorio:
                        '1 TABELIONATO DE PROTESTO DE TÍTULOS DE CURITIBA',
                    nu_cartorio: '1',
                    cd_uf: 'PR',
                    telefone_cartorio: '41-99999-9999',
                    endereco_cartorio: 'Rua Teste, 200'
                },
                {
                    cd_ibge_comarca: '222',
                    nm_cartorio:
                        '1 TABELIONATO DE PROTESTO DE TÍTULOS DE CURITIBA',
                    nu_cartorio: '2',
                    cd_uf: 'PR',
                    telefone_cartorio: '41-99999-9999',
                    endereco_cartorio: 'Rua Teste, 200'
                }
            ]
        };
    },

    methods: {
        botaoListarCartorios() {
            this.loading = true;
            let filtros = {};

            if (this.filtros.cd_ibge_comarca) {
                filtros.cd_ibge_comarca = this.filtros.cd_ibge_comarca;
            }
            if (this.filtros.nu_cartorio) {
                filtros.nu_cartorio = this.filtros.nu_cartorio;
            }
            if (this.filtros.nm_cartorio) {
                filtros.nm_cartorio = this.filtros.nm_cartorio;
            }
            if (this.filtros.cd_uf) {
                filtros.cd_uf = this.filtros.cd_uf;
            }
            if (this.filtros.nm_municipio) {
                filtros.nm_municipio = this.filtros.nm_municipio;
            }

            return new Promise((resolve, reject) => {
                try {
                    let cartorioService = new CartorioService(
                        Vue.http,
                        this.$store
                    );
                    cartorioService.buscarCartorios(filtros).then(
                        response => {
                            if (response.status == 200) {
                                this.cartorios = response.body;
                                this.loading = false;
                            }
                        },
                        error => {
                            console.log(error);
                            this.loading = false;
                            this.mensagemRetorno =
                                'Houve um erro ao buscar os Cartórios.';
                            this.snackbarColor = 'red white--text';
                            this.snackbar = true;
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        exibirDetalhe(cartorio) {
            this.cartorio = new Cartorio();
            let cartorioService = new CartorioService(Vue.http, this.$store);
            cartorioService.getDetalheCartorio(cartorio).then(
                response => {
                    this.cartorio = response.body;
                    this.dialogEditar = true;
                    this.dialogLoading = false;
                },
                error => {
                    this.mensagem = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        },
        closeDialogEditar() {
            this.dialogEditar = false;
        },
        validarSalvarCartorio() {
            if (this.isInvalid) {
                this.validarForm = true;
                this.mensagemRetorno =
                    'Favor, preencher todos os campos obrigatórios.';
                this.snackbarColor = 'red white--text';
                this.snackbar = true;
            } else {
                this.salvarCartorio();
            }
        },
        salvarCartorio() {
            let cartorio = this.cartorio;
            return new Promise((resolve, reject) => {
                try {
                    let cartorioService = new CartorioService(
                        Vue.http,
                        this.$store
                    );
                    cartorioService.salvarCartorio(cartorio).then(
                        response => {
                            if (response.status == 200) {
                                this.loading = false;
                                this.mensagemRetorno =
                                    'Cartório editado com sucesso!';
                                this.snackbarColor = 'success white--text';
                                this.snackbar = true;
                                this.dialogEditar = false;
                            }
                        },
                        error => {
                            console.log(error);
                            this.loading = false;
                            this.mensagemRetorno =
                                'Houve um erro ao editar o Cartório.';
                            this.snackbarColor = 'red white--text';
                            this.snackbar = true;
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        }
    },
    mounted() {}
};
</script>
