export default class CartorioService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }

    async buscarCartorio(cd_ibge_comarca, nu_cartorio) {
        const cartorioDetalhes = await this._http.get('cartorios', {
            params: { cd_ibge_comarca, nu_cartorio }
        });
        return cartorioDetalhes.body;
    }

    buscarCartorios(filtros) {
        return this._http.get('cartorios', {
            params: {
                cd_ibge_comarca: filtros.cd_ibge_comarca,
                cd_uf: filtros.cd_uf,
                nu_cartorio: filtros.nu_cartorio,
                nm_cartorio: filtros.nm_cartorio,
                nm_municipio: filtros.nm_municipio
            }
        });
    }

    getDetalheCartorio(cartorio) {
        return this._http.get(`cartorios/`+cartorio.id, {});
    }

    salvarComarcas(comarca) {
        return this._http.put(`comarcas/update`, {
            cd_ibge_comarca: comarca.cd_ibge_comarca,
            ds_limite_diario: comarca.ds_limite_diario,
            st_ativo: comarca.st_ativo
        });
    }

    salvarCartorio(cartorio) {
        return this._http.put(`cartorios/`+ cartorio.id, cartorio, {
            cd_ibge_comarca: cartorio.cd_ibge_comarca,
            nu_cartorio: cartorio.nu_cartorio,
            id: cartorio.id
        });
    }
}
